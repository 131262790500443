<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="类型"
                style="width: 150px"
                v-model="form.type"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="状态"
                style="width: 150px"
                v-model="form.status"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-space v-if="$getPermission(addPath)">
              <a-button @click="qrCodeVisible = true">匿名发布</a-button>
              <a-button @click="$router.push(addPath)" type="danger"
                >实名发布</a-button
              >
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-skeleton :loading="loading" :paragraph="{ rows: 8 }">
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id">
            <div class="header">
              <a-tag color="green" v-if="item.isPublic"> 公开 </a-tag>
              <a-tag color="red" v-else> 不公开 </a-tag>

              <div class="title">{{ item.title }}</div>

              <div class="extra">
                <DataDictFinder
                  dictType="informStatus"
                  :dictValue="String(item.status)"
                  iconType="badge"
                />
              </div>
            </div>

            <div class="meta">
              <div class="left">
                <a-icon type="tag" class="icon" />
                <span class="value">
                  <DataDictFinder
                    dictType="informType"
                    :dictValue="item.type"
                  />
                </span>
              </div>
              <div>
                <a-icon type="eye" class="icon" />
                <span class="value">{{ item.readingTimes }}</span>
              </div>
              <div>
                <a-icon type="user" class="icon" />
                <span class="value">{{ item.informerName }}</span>
              </div>
              <div>
                <a-icon type="clock-circle" class="icon" />
                <span class="value">{{ item.createAt }}</span>
              </div>

              <div style="margin-left: auto">
                <a-space>
                  <a
                    href="#"
                    v-if="
                      $getPermission(editPath) &&
                      String(item.status) === '0' &&
                      isMe(item)
                    "
                    @click.prevent="edit(item)"
                    >修改</a
                  >
                  <a
                    href="#"
                    v-if="$getPermission(detailPath)"
                    @click.prevent="goDetail(item)"
                    >详情</a
                  >

                  <template v-if="isMe(item) && String(item.status) !== '3'">
                    <a href="#" @click.prevent="closeText(item)">关闭</a>
                  </template>

                  <template v-if="isMe(item)">
                    <a
                      href="#"
                      class="danger"
                      @click.prevent="deleteText(item)"
                      v-if="String(item.status) !== '-1'"
                      >删除</a
                    >
                    <a href="#" v-else @click.prevent="cancel(item)">恢复</a>
                  </template>
                </a-space>
              </div>
            </div>
          </div>
        </div>
      </a-skeleton>

      <div class="right" style="margin-top: 12px">
        <a-pagination
          :default-current="current"
          :pageSize="pageSize"
          :total="total"
          @change="onChange"
        />
      </div>
    </a-card>

    <Hide :visible="qrCodeVisible" @cancel="qrCodeVisible = false" />
  </div>
</template>
  
  
<script>
import watermark from "@/mixins/watermark";
import { fetchList, recover, remove, close } from "./api";
import { mapGetters, mapState } from "vuex";

import Hide from "./components/hide.vue";
export default {
  mixins: [watermark],

  components: {
    Hide,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      qrCodeVisible: false,
    };
  },

  computed: {
    addPath() {
      const path = this.$route.path;
      return path.replace("/history", "/add");
    },
    editPath() {
      const path = this.$route.path;
      return path.replace("/history", "/edit");
    },
    detailPath() {
      const path = this.$route.path;
      return path.replace("/history", "/detail");
    },
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("informType");
    },
    statusList() {
      return this.findDataDict("informStatus");
    },
    ...mapState("auth", ["user"]),
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list) && typeof res.totalSize === "number") {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(current) {
      console.log("current", current);
      this.current = current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    goDetail(text) {
      this.$router.push(this.detailPath + "?id=" + text.id);
    },
    edit(text) {
      this.$router.push(this.editPath + "?id=" + text.id);
    },
    isMe(detail) {
      return this.user.uuid === detail.informer;
    },

    closeText(text) {
      const that = this;
      this.$confirm({
        title: "关闭后无法恢复，是否继续？",
        onOk() {
          close({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    cancel(text) {
      recover({
        id: text.id,
      }).then(() => {
        this.getList();
      });
    },
    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.list {
  .item {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
    // margin-bottom: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      margin-left: 4px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1em;
    }

    .extra {
      margin-left: auto;
    }
  }

  .content {
    margin-bottom: 12px;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .meta {
    // margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #999;

    .value {
      margin-left: 4px;
    }
  }
}
</style>